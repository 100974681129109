import React, { useEffect } from "react"
import CloseIcon from "@material-ui/icons/Close"
import "./StaticBanner.css"
import { Grid } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import {
  activateBanner,
  deactivateBanner,
} from "../../redux/slices/bannerSlice"

function StaticBanner() {
  const dispatch = useDispatch()
  const isActive = useSelector(state => state.bannerSlice.isActive)

  useEffect(() => {
    function staticBanner(x) {
      if (x.matches) {
        dispatch(activateBanner())
      } else {
        dispatch(deactivateBanner())
      }
    }

    var x = window.matchMedia("(max-width: 600px)")
    staticBanner(x)
    x.addEventListener("change", staticBanner)
  }, [])

  const handleBannerClick = () => {
    dispatch(deactivateBanner())
  }

  if (isActive) {
    return (
      <Grid className="static-banner-root">
        <h4 className="banner-text">
          Disclaimer: Use your desktop computer to run platform. Website is
          unresponsive in mobile at times
        </h4>
        <Grid className="static-banner-icon-container">
          <CloseIcon fontSize="small" onClick={handleBannerClick} />
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default StaticBanner
